import { IS_DEV, IS_TESTING } from "@/__main__/constants.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import { DISPLAY_AD_CLASS } from "@/feature-ads/constants/constants.mjs";
import {
  destroySlots,
  elementSlotMap,
  initScript,
  refreshSlots,
  registerSlot,
} from "@/feature-ads/display-server-google.mjs";
import fetchRemoteAdConfig from "@/feature-ads/fetch-remote-ad-config.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import { promiseTimeout } from "@/feature-ads/util/promise-timeout.mjs";
import { devError } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

// Welcome to display ads. 💣
//
// There are a few difficulties dealing with ads that should be stated here:
//
// - Timing is everything. Make sure things are event-based and fire in the
//   correct sequence. The penalty for doing this wrong will reflect in ad revenue.
//
// - Be very careful with when to show ads and how many. In general we want
//   there to be a linear relationship with time spent and ads shown.
//
// - Do not refresh ads while the page is not visible. This will negatively affect
//   viewability metrics!

const { SCRIPTS } = adsRefs;

let hasInitialized = false;

const REMOTE_TIMEOUT = 3000;

async function initializeScript() {
  if (hasInitialized || IS_TESTING) return;

  // Optimistically assumption here, but we wanna prevent
  // multiple instances.
  hasInitialized = true;

  // continue on this setup error to treat as generic international traffic
  try {
    const cfg = await promiseTimeout(fetchRemoteAdConfig(), REMOTE_TIMEOUT);
    // do not set up vendor scripts if display ads are entirely disabled
    if (!cfg.enabled) return;
  } catch (e) {
    devError("[ads] Failed to setup country-based config", e);
  }

  if (!Object.keys(SCRIPTS).length) throw new Error("[ads] No scripts to load");

  const initScripts = Object.entries(SCRIPTS)
    .filter(([k]) => k !== "GPT_SRC")
    .map(([_, v]) => initScript(v));
  await Promise.all(initScripts);

  // IMPORTANT! Load Google last.
  // Pwt.js must load before gpt.js in order for the Wrapper tag to read GPT slots, intercept GAM
  // calls, and automatically inject bid values. Otherwise, PubMatic wrapper code will not execute.
  // See: https://community.pubmatic.com/pages/viewpage.action?spaceKey=OP&title=On-page+integrations+for+GAM
  if (SCRIPTS.GPT_SRC) initScript(SCRIPTS.GPT_SRC);
}

let displayTimer = null;

// Explanation: we want there to be a throttle here just in case not all
// ad elements are rendered in the same tick.
const DISPLAY_THROTTLE = 30; // ms

export function findAds(node) {
  const nodes = node.classList.contains(DISPLAY_AD_CLASS)
    ? [node]
    : Array.from(node.querySelectorAll(`.${DISPLAY_AD_CLASS}`));
  return nodes;
}

adsRefs.registerAd = registerSlot;
adsRefs.destroyAds = destroySlots;
function registerAds(node: HTMLElement) {
  const ads = findAds(node);
  const result = [];
  for (const ad of ads) {
    ad.innerHTML = "";
    result.push(adsRefs.registerAd(ad.id));
  }
  return result;
}

const obs =
  typeof MutationObserver === "undefined"
    ? null
    : new MutationObserver((mutations) => {
        const registrations = [];

        for (const {
          addedNodes,
          removedNodes,
          target,
          attributeName,
          oldValue,
        } of mutations) {
          for (const node of addedNodes) {
            if (!(node instanceof HTMLElement)) continue;
            registrations.push(...registerAds(node));
          }

          for (const node of removedNodes) {
            if (!(node instanceof HTMLElement)) continue;
            const ads = findAds(node);
            adsRefs.destroyAds(
              ads.map((element) => elementSlotMap.get(element)),
            );
          }

          if (
            attributeName === "id" &&
            target instanceof HTMLElement &&
            target.classList?.contains(DISPLAY_AD_CLASS)
          ) {
            // id was added or changed
            registrations.push(adsRefs.registerAd(target.id));
            if (!oldValue) continue; // must clean up old slot
            registrations.push(
              adsRefs.destroyAds([elementSlotMap.get(target)]),
            );
          }
        }
        if (!registrations.length) return;
        clearTimeout(displayTimer);
        displayTimer = setTimeout(async () => {
          await Promise.all(registrations);
          await refreshSlots();
        }, DISPLAY_THROTTLE);
      });

async function initialRegistration() {
  if (!globals.document) return;
  try {
    await adsRefs.waitForAdsConfirmation();
    await initializeScript();
    const registrations = registerAds(globals.document.body);
    if (registrations.length) {
      await Promise.all(registrations);
      await refreshSlots();
    }
  } catch (e) {
    devError("[ads] Failed to initialize display ads", e);
    // we don't care about mapped output, this most likely came from vendor code
    // @ts-ignore
    if (!IS_DEV && !e?.isTrusted) {
      eventBus.emit(EVENT_ERROR, {
        error: e,
        tags: ["display_ad", "initialize_display_ads"],
      });
    }
  }
}

export function setupGlobalDisplayAd() {
  initialRegistration();
  if (!obs) return;
  obs.observe(globals.document.body, {
    childList: true,
    subtree: true,
    attributes: true,
    attributeFilter: ["id"],
  });
}

export function teardownDisplayProvider() {
  if (!obs) return;
  obs.disconnect();
}
