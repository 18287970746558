import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import { didInitConsent } from "@/feature-consent/consent.mjs";
import globals from "@/util/global-whitelist.mjs";

export default async function getPlatformId() {
  await didInitConsent;

  let hash: string | undefined;

  const h = readState[atob("dXNlcg")]?.[atob("ZW1haWw")];
  hash ||= h && ((await getSHA256Hash(h)) as string);

  hash ||= (await blitzMessage(EVENTS.PLATFORM_ID)) as string;

  return hash;
}

const getSHA256Hash = async (input) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await globals.crypto.subtle.digest(
    "SHA-256",
    textAsBuffer,
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, "0"))
    .join("");
  return hash;
};
