import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import { Container } from "@/feature-ads/HeaderWrapper.style.jsx";
import adsRefs from "@/feature-ads/refs.mjs";
import adVideoRefs from "@/feature-ads-video-new/AdVideo.refs.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function HeaderWrapper({ children }) {
  const { shouldShowAds: shouldShowAdsHandlers } = useSnapshot(adsRefs);
  const { AdVideoContainer } = useSnapshot(adVideoRefs);

  const {
    volatile: { "ads-left-rail": showLeftRail = false },
  } = useSnapshot(readState);

  const shouldShowAds = shouldShowAdsHandlers.every((fn) => fn());

  if (!shouldShowAds) {
    return children;
  }

  return (
    <Container
      style={{
        // sic: left is 2 rails but it balances out as 0
        "--rail-count": showLeftRail ? 0 : 1,
      }}
    >
      <AdVideoContainer />
      {children}
    </Container>
  );
}

export default HeaderWrapper;
