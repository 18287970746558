import { readState } from "@/__main__/app-state.mjs";
import { IS_NODE, IS_TESTING } from "@/__main__/constants.mjs";
import { ADITUDE_SLOTS } from "@/feature-ads/constants/aditude.mjs";
import { displayEvents } from "@/feature-ads/display-events.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import getPlatformId from "@/feature-ads/util/platform-id.mjs";
import { devError, devLog } from "@/util/dev.mjs";
import isNonNullable from "@/util/is-non-nullable.mjs";

const ADITUDE_SRC =
  "https://dn0qt3r0xannq.cloudfront.net/blitz-ONuZ1Ty9qx/blitz-default/prebid-load.js";

// eslint-disable-next-line no-unused-vars
const g = globalThis;

// adding our own promise implementation since we do not control when this event
// is fired. we need to be able to know if this fired before we initialized
let resolveAts: AnyFunction;
const atsEnvelopeReady = new Promise((resolve) => {
  resolveAts = resolve;
});
// https://docs.liveramp.com/privacy-manager/en/ats-js-functions-and-events.html#envelopemoduleready
if (!IS_NODE) {
  g.addEventListener("envelopeModuleReady", function () {
    resolveAts();
  });
}

export function setupAditude(full?: boolean) {
  if (IS_NODE) return;

  const { SCRIPTS, vendorSetup, googleInit, googleCmd } = adsRefs;

  displayEvents.on(displayEvents.SET_TARGETING, (k, v, slot) => {
    // non-null check because casting to string will make null/undefined into "null"/"undefined"
    if (!slot && isNonNullable(v)) {
      tudeCmd(() => {
        g.tude.setPageTargeting({ [k]: String(v) });
      });
      return;
    }

    // fallback to google
    googleCmd(() => {
      slot ||= g.googletag.pubads();
      if (isNonNullable(v)) {
        // Set the value
        return slot.setTargeting(k, String(v));
      }
      // If v is nullish clear the key
      // only clear if key exists, otherwise a warning is thrown
      const curArr = slot.getTargeting(k);
      if (curArr?.length > 0) slot.clearTargeting(k);
    });
  });

  displayEvents.ready(full ? "tude" : "aditude");

  for (const key in SCRIPTS) {
    delete SCRIPTS[key];
  }

  SCRIPTS.ADITUDE_SRC = ADITUDE_SRC;

  vendorSetup.ADITUDE_SRC = () => {
    return Promise.all([
      googleCmd(googleInit),
      (async () => {
        try {
          const e = await getPlatformId();
          devLog(`[ads platform id] ${e}`);
          if (!e) return;
          atsEnvelopeReady.then(() => {
            // using vendor global
            if (!g.atsenvelopemodule)
              return devError("atsenvelopemodule not defined");
            g.atsenvelopemodule.setAdditionalData({
              type: atob("ZW1haWxIYXNoZXM"),
              id: [e],
            });
          });
          await tudeCmd(() => {
            g.tude.setIdProfile({
              e,
              i4: readState.search.regionData.remoteAddress,
            });
            g.apstag.rpa({
              hashedRecords: [
                {
                  type: atob("ZW1haWw"),
                  record: e,
                },
              ],
            });
          });
        } catch (e) {
          devError("Failed to setup platform id", { e });
        }
      })(),
    ]);
  };

  if (full) {
    const script = g.document.createElement("script");
    script.src = ADITUDE_SRC;
    script.async = true;
    script.onload = vendorSetup.ADITUDE_SRC;
    g.document.head.appendChild(script);
    g.tudeControl = true;
    return;

    // Keeping the below cause I think that will be the follow-up
    // adsRefs.registerAd = registerAd;
    // adsRefs.destroyAds = () => {};

    // g.tudeControl = true;
  }

  adsRefs.internalRefresh = async (targetedSlots) => {
    await tudeCmd(() => {
      g.tude.refreshAdsViaDivMappings(
        targetedSlots
          .filter((slot) => slot.getSlotElementId() in ADITUDE_SLOTS)
          .map((slot) => {
            const id = slot.getSlotElementId();
            return {
              divId: id,
              baseDivId: ADITUDE_SLOTS[id],
            };
          }),
      );
    });
  };
}

export function tudeCmd(fn: () => void | Promise<void>) {
  if (IS_TESTING) return;
  g.tude ||= { cmd: [] };
  return new Promise((resolve, reject) => {
    g.tude.cmd.push(async () => {
      try {
        resolve(await fn());
      } catch (e) {
        reject(e);
      }
    });
  });
}
