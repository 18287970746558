import { css, styled } from "goober";
import { desktop } from "clutch/src/Style/style.mjs";

import { ADS_COLUMN_CLASS } from "@/feature-ads/AdWrapper.jsx";
import {
  AD_WIDTH,
  DISPLAY_AD_CLASS,
} from "@/feature-ads/constants/constants.mjs";

export const externalContainerCss = () => css`
  &:has(.${ADS_COLUMN_CLASS} .${DISPLAY_AD_CLASS}) {
    --ad-rail-space: calc(${AD_WIDTH}px + var(--sp-6));
  }
`;

export const Container = styled("div")`
  --header-container: calc(
    var(--sp-container) + var(--rail-count, 1) * var(--ad-rail-space)
  );

  ${desktop} {
    --header-container: 100%;
  }
`;

export const titleBarCss = () => css`
  --header-container: calc(
    var(--sp-container) + var(--rail-count, 1) * var(--ad-rail-space)
  );

  ${desktop} {
    --header-container: 100%;
  }
`;
